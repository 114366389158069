<template>
  <ion-modal
    class="modal-big"
    :is-open="isOpen"
    mode="md"
    :backdrop-dismiss="true"
    :show-backdrop="true"
    @didDismiss="dismissModal"
  >
    <ion-header translucent>
      <ion-toolbar class="toolbar">
        <div class="d-flex justify-content-between align-items-center px-3 py-2">
          <div class="d-fex align-items-center flex-column">
            <div class="title">Provider Fee Information</div>
            <small style="font-size: 10px"
              >The fee helps us prevent fraud, scams, and keep the gem services running smoothly</small
            >
          </div>

          <div>
            <ion-buttons slot="end">
              <ion-button @click="dismissModal">
                <i class="ti-close" />
              </ion-button>
            </ion-buttons>
          </div>
        </div>
      </ion-toolbar>
    </ion-header>

    <div class="modal-inner-content">
      <div class="p-3">
        <template v-for="(transaction, index) in transactions" :key="index">
          <div
            class="gem-content position-relative p-2 mb-2"
            :class="{ 'border-check': isHighlighted(transaction.plan) }"
          >
            <div v-if="isHighlighted(transaction.plan)" class="position-absolute badge">YOU ARE HERE</div>
            <div
              :style="transaction.plan === 'subscription_level_1' ? 'color: #00B4C5' : 'color: #9874DB'"
              :class="{ 'gradient-text': transaction.plan === 'subscription_level_2' }"
              class="sub-title"
            >
              <strong>{{ transaction.precentage }} fee</strong> for transactions for:
            </div>

            <div class="p-2" v-if="transaction.plan === 'condition'" v-html="transaction.description" />
            <div v-else class="p-2 d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-center">
                • <SubPlanBadge class="mx-1" v-if="transaction.plan !== 'default'" :plan="transaction.subPlan" />
                {{ transaction.description }}
              </div>
              <div>
                <SubPlanBadge
                  class="mx-1"
                  v-if="transaction.plan !== 'default'"
                  :plan="transaction.subPlan"
                  :text="transaction.subText"
                />
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </ion-modal>
</template>

<script lang="ts" setup>
import SubPlanBadge from '@/shared/components/SubPlanBadge.vue';
const props = defineProps({
  isOpen: {
    type: Boolean,
    default: false,
  },
  user: {
    type: String,
    default: '',
  },
  providerFeeInfo: {
    type: Object,
    default: () => {},
  },
});
const emits = defineEmits(['close']);

const isOpen = toRef(props, 'isOpen');
const providerFeeInfo = toRef(props, 'providerFeeInfo');
const transactions = computed(() => {
  return [
    {
      precentage: '3.0%',
      description: 'New accounts',
      plan: 'default',
    },
    {
      precentage: '1.5%',
      description: `
      ○ Accounts:<br>
          &nbsp;&nbsp;&nbsp;• Older than 30 days<br>
          &nbsp;&nbsp;&nbsp;• With 1 character (private or public)<br>
          &nbsp;&nbsp;&nbsp;• With at least 1 virtual item in inventory.<br>
      `,
      plan: 'condition',
    },
    {
      precentage: '0.8%',
      description: 'Accounts',
      plan: 'subscription_level_1',
      subText: 'Upgrade Plan',
      subPlan: 'plus',
    },
    {
      precentage: '0.0%',
      description: 'Accounts',
      plan: 'subscription_level_2',
      subText: 'Upgrade Plan',
      subPlan: 'ultra',
    },
  ];
});

const isHighlighted = (plan: string) => {
  return providerFeeInfo.value[plan] === true;
};

const dismissModal = () => {
  emits('close');
};
</script>

<style lang="sass" scoped>
.badge
  background: #B136E4
  color: white
  font-size: 10px
  font-weight: bold
  border-radius: 20px
  padding: 1px 6px 1px 6px
  top: -11px
.gradient-text
  background: linear-gradient(111.47deg, #E338FC 0%, #AE38E5 25%, #886EFF 50%, #4FBDE5 75%, #6DFFE5 100%)
  -webkit-background-clip: text
  color: transparent !important
  display: inline-block
.amount-text
  color: #ae38e5
.border-bottom
  border-bottom: 1px dashed #C6C8D6
  height: 0

.summary-content
  background: #f9f9ff

.img
  width: 24px
  height: 24px
  object-fit: cover

.sub-title
  font-weight: bold
  font-size: 18px

.gem-amount-content
  border-radius: 16px
.border-check
  border: 1px solid #DD17D7 !important

.gem-balance-content
  background: #fff
  border-radius: 20px

.gem-content
  background: #fff
  border-radius: 16px

.dark .toolbar
  --background: #17074c !important

.modal-inner-content
  background: var(--ion-background-color, #F9F9FF)
  max-height: 90vh
  overflow-x: hidden
  overflow-y: auto

.modal-big
  --width: 500px
  --height: auto
  @media(max-width: 576px)
    --width: 100%

.close-button
  width: 50px

.title
  color: #214163
  font-size: 20px

.text
  color: #214163

.gem-input-container
  height: 50px !important
</style>

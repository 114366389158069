<template>
  <ion-modal
    class="modal-big"
    :is-open="isOpen"
    mode="md"
    :backdrop-dismiss="true"
    :show-backdrop="true"
    @didDismiss="dismissModal"
    :style="isSendingGems && minAmount ? '--width: 700px' : ''"
  >
    <ion-header translucent>
      <ion-toolbar class="toolbar">
        <div class="d-flex justify-content-between align-items-center px-3 py-2">
          <div v-if="isSendingGems" class="clickable-item-hov" @click="goBack">
            <i class="ti-arrow-left" />
          </div>

          <div class="title">
            Send Gems to <strong>{{ get(currentProfile, 'username') }}</strong>
          </div>
          <div>
            <ion-buttons slot="end">
              <ion-button @click="dismissModal">
                <i class="ti-close" />
              </ion-button>
            </ion-buttons>
          </div>
        </div>
      </ion-toolbar>
    </ion-header>

    <div class="modal-inner-content">
      <div class="p-3">
        <div v-if="isSendingGems && minAmount" class="p-2">
          <div>Not enough Gems. Purchase more to Continue.</div>
          <StoreOffersSection
            :products="gemPacks"
            :white-card-color="true"
            :amountSection="true"
            :ring-shop-btn="true"
          />
        </div>
        <div v-else-if="isSendingGems">
          <small>Sending:</small>
          <div class="gem-content p-2 mb-2">
            <div class="d-flex align-items-center">
              <inline-svg src="/gem.svg" width="18" height="18" />
              <div class="mx-2">{{ totalAmount }}</div>
            </div>
          </div>
          <small>To:</small>
          <div class="gem-content p-2">
            <div class="d-flex align-items-center">
              <img
                v-user-image
                :src="get(currentProfile, 'profile_picture_url')"
                height="25"
                width="25"
                style="border-radius: 20px"
              />
              <div class="mx-2">{{ get(currentProfile, 'username') }}</div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="gem-content p-2">
            <div class="gem-balance-content d-flex justify-content-between align-items-center p-2">
              <div class="sub-title">TOTAL GEM BALANCE</div>
              <div class="d-flex align-items-center">
                <inline-svg src="/gem.svg" width="18" height="18" />

                <div class="mx-2">{{ totalGems }}</div>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-end">
            <small class="text-black my-1">Only <strong>paid gems</strong> can be sent</small>
          </div>

          <div :class="['gem-amount-content ', { 'border-red': amount && amount < 200 }]">
            <div class="d-flex mx-2 align-items-center gem-input-container">
              <inline-svg class="img" src="/gem.svg" />
              <ion-input
                v-model.number="amount"
                placeholder="type amount to gift. e.g.500"
                class="a-input"
                type="number"
                @input="inputSave"
              />
            </div>
          </div>
          <div class="d-flex justify-content-end">
            <small class="text-danger" v-if="amount && amount < 200">Minimum amount to send is 200 gems</small>
          </div>
        </div>
      </div>
    </div>
    <div class="summary-content p-3" v-if="isTotalSummaryContentOpened || (amount && amount >= 200 && !isSendingGems)">
      <div class="sub-title mb-2">Total Summary:</div>
      <template v-for="(summary, index) in totalSummary" :key="index">
        <div
          class="gem-balance-content d-flex align-items-center justify-content-between p-2 mb-2"
          :style="get(summary, 'value') === 'send' ? 'background: #ae38e5;color: white' : ''"
        >
          <div class="d-flex align-items-center">
            {{ get(summary, 'title') }}
            <span v-if="get(summary, 'value') === 'fee'" class="mx-1 d-flex align-items-center"
              >(
              <p class="amount-text">{{ providerFeePercent }}%</p>
              )</span
            >
            <i
              v-if="get(summary, 'icon')"
              class="clickable-item-hov"
              @click="openProviderFeeModal"
              :class="summary.icon"
              style="color: #ae38e5"
            />
          </div>
          <div class="flex-grow-1 mx-2 border-bottom"></div>
          <div>
            <div class="mr-2" :class="{ 'amount-text': get(summary, 'value') === 'fee' }">
              {{ get(summary, 'amount') <= 0 && get(summary, 'value') === 'total' ? '0' : get(summary, 'amount') }}
            </div>
          </div>
        </div>
      </template>
    </div>
    <div v-if="isSendingGems && !minAmount" class="summary-content p-3">
      <ion-list class="bg-transparent">
        <ion-radio-group :value="message" mode="md">
          <div class="sub-title">Add a Message!</div>

          <ion-item
            lines="none"
            v-for="(category, index) in msgCategories"
            :key="`lst-cat-${category.value}`"
            class="items mb-2"
            @click.prevent.stop="setMsg(category)"
          >
            <div
              :class="
                message === 'other' && category.value === 'other'
                  ? 'd-flex flex-column w-100'
                  : 'd-flex align-items-center'
              "
            >
              <div class="d-flex align-items-center">
                <div><ion-radio :value="category.value"></ion-radio></div>
                <div>
                  <ion-label class="ml-2">{{ category.name }}</ion-label>
                </div>
              </div>
              <div style="padding: 0px 8px 8px 0px">
                <ion-textarea
                  id="otherTextarea"
                  v-if="message === 'other' && category.value === 'other'"
                  v-model="text"
                  placeholder="Write your own message here"
                  class="w-100 text-area"
                  rows="3"
                  cols="20"
                />
              </div>
            </div>
          </ion-item>
        </ion-radio-group>
      </ion-list>
    </div>
    <ion-footer translucent v-if="(amount && amount >= 200) || isTotalSummaryContentOpened">
      <ion-toolbar class="toolbar">
        <div class="d-flex justify-content-center text-center align-items-center">
          <ion-button
            :class="isSendingGems ? 'w-48' : ''"
            style="--background: #ae38e5"
            @click="action"
            :disabled="(amount && amount < 200) || (isSendingGems && minAmount) || isLoading"
          >
            <div v-if="isSendingGems">
              <ChLoading v-if="isLoading" size="sm" class="spinner" />
              <span v-else>{{ isSendingGems && minAmount ? '' : 'Confirm and' }} Send Gems</span>
            </div>
            <span v-else>Send {{ totalAmount }} Gems</span>
          </ion-button>
        </div>
      </ion-toolbar>
    </ion-footer>
    <ProviderFeeModal
      :is-open="isOpenProviderFeeModal"
      @close="isOpenProviderFeeModal = false"
      :provider-fee-info="providerFeeInfo"
    />
  </ion-modal>
</template>

<script lang="ts" setup>
import { getSelf, providerFeeInformation, transferUserGems } from '../actions/users';
import { profileStore } from '../pinia-store/profile';
import ProviderFeeModal from './ProviderFeeModal.vue';
import StoreOffersSection from '~/shared/pages/store/StoreOffersSection.vue';
import { getGemPackageListings } from '@/shared/actions/payments';
import { toast } from '~/shared/native/toast';

const props = defineProps({
  isOpen: {
    type: Boolean,
    default: false,
  },
});
const totalGems = ref(0);
const emits = defineEmits(['close', 'sent']);
const isSendingGems = ref(false);
const gemPacks = ref([]);
const amount: any = ref<number | null>(null);
const isOpen = toRef(props, 'isOpen');
const isTotalSummaryContentOpened = ref(false);
const { currentProfile } = profileStore();
const providerFeeInfo: any = ref({});
const isOpenProviderFeeModal = ref(false);
const message = ref('');
const text = ref('');
const isLoading = ref(false);
const msgCategories = ref([
  { name: 'Happy Birthday!', value: 'birthday' },
  { name: 'For the commission!', value: 'commission' },
  { name: 'Have a little treat!', value: 'treat' },
  { name: 'Other.', value: 'other' },
]);
watch(isOpen, async () => {
  if (isOpen.value) {
    const { gems } = await getSelf();
    totalGems.value = gems;
    const resp = await providerFeeInformation();
    providerFeeInfo.value = resp;
  }
});

const goBack = () => {
  isSendingGems.value = false;
  isTotalSummaryContentOpened.value = true;
};

const inputSave = () => {
  if (isTotalSummaryContentOpened.value) return;
  else if (amount.value && amount.value >= 200) {
    isTotalSummaryContentOpened.value = true;
  }
};

// const change = (value: string) => {
//   if (value === 'other') {
//     setTimeout(() => {
//       try {
//         const textarea = document.getElementById('otherTextarea');
//         if (textarea) textarea.focus();
//       } catch (e) {}
//     }, 200);
//   }
// };

const setMsg = (category: any) => {
  message.value = category.value;
};
const fetchGemShopListings = async () => {
  getGemPackageListings().then((res: any) => {
    gemPacks.value = res.bundles;
  });
};
const providerFeePercent = computed(() => {
  if (providerFeeInfo.value.default) {
    return 3;
  } else if (providerFeeInfo.value.condition) {
    return 1.5;
  } else if (providerFeeInfo.value.subscription_level_1) {
    return 0.8;
  } else if (providerFeeInfo.value.subscription_level_2) {
    return 0;
  }
  return 2.5;
});
const providerFee = computed(() => {
  if (providerFeeInfo.value.default) {
    return Math.ceil(amount.value * (3 / 100));
  } else if (providerFeeInfo.value.condition) {
    return Math.ceil(amount.value * (1.5 / 100));
  } else if (providerFeeInfo.value.subscription_level_1) {
    return Math.ceil(amount.value * (0.8 / 100));
  } else if (providerFeeInfo.value.subscription_level_2) {
    return 0;
  }
  return Math.ceil((amount.value * 1.5) / 100);
});

const totalAmount = computed(() => {
  return amount.value - providerFee.value;
});

const resetData = () => {
  amount.value = null;
  message.value = '';
  text.value = '';
  isSendingGems.value = false;
  isTotalSummaryContentOpened.value = false;
};
const totalSummary = computed(() => {
  return [
    {
      title: 'Total Number of Gems',
      amount: amount.value,
      value: 'total',
    },
    {
      title: 'Provider Fee',
      amount: `-${providerFee.value}`,
      icon: 'ti-info-alt',
      value: 'fee',
    },
    {
      title: `${get(currentProfile.value, 'username')} gets`,
      amount: totalAmount.value,
      value: 'send',
    },
  ];
});
const dismissModal = () => {
  resetData();
  emits('close');
};

const minAmount = computed(() => {
  return totalGems.value <= amount.value;
});
const action = async () => {
  if (amount.value && amount.value < 200) return;
  if (isSendingGems.value) {
    try {
      isLoading.value = true;
      const payload = {
        transfer_to: get(currentProfile.value, 'id'),
        gems_count: amount.value,
        message:
          message.value === 'other'
            ? text.value
            : msgCategories.value.find((category) => category.value === message.value)?.name,
      };
      await transferUserGems(payload);
      emits('sent');
      isLoading.value = false;
    } catch (error) {
      toast.show('Cannot Transfer. Try Again Later', 'nonative', 'danger');
    }
  } else {
    if (minAmount.value) {
      fetchGemShopListings();
    }
    isSendingGems.value = true;
    isTotalSummaryContentOpened.value = false;
  }
};
const openProviderFeeModal = () => {
  isOpenProviderFeeModal.value = true;
};
</script>
<style lang="sass" scoped>
.text-area
  background: #F9F9FF
  border-radius: 10px
.dark .text-area
  background: #214163 !important
.items
  background: white !important
  border-radius: 11px
  border: 1px solid #e3e3e3

.amount-text
  color: #ae38e5
.border-bottom
  border-bottom: 1px dashed #C6C8D6
  height: 0

.summary-content
  background: #f9f9ff
.dark .summary-content
  background: #214163 !important
.img
  width: 24px
  height: 24px
  object-fit: cover

.sub-title
  color: #431C89
  font-weight: bold
  font-size: 16px
.dark .sub-title
  color: #fff !important
.gem-amount-content
  border-radius: 16px
  border: 2px solid #C6C8D6
  &.border-red
    border: 1px solid red

.gem-balance-content
  background:  #fff
  border-radius: 20px
  height: 40px
.dark .gem-balance-content
  background: #17074C !important
.gem-content
  background: #F9F9FF
  border-radius: 16px
.dark .gem-content
  background: #214163 !important

.dark .toolbar
  --background: #17074c !important

.modal-inner-content
  background: var(--ion-background-color, #fff)
  max-height: 60vh
  overflow-x: hidden
  overflow-y: auto

.modal-big
  --width: 500px
  --height: auto
  @media(max-width: 576px)
    --width: 100%

.close-button
  width: 50px

.title
  color: #214163
  font-size: 20px

.gem-input-container
  height: 50px !important
</style>

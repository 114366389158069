<template>
  <div class="profile-popover">
    <ion-item class="item-container d-flex pointer no-select" @click="onPress('gems')">
      <div class="menu-item d-flex align-items-center no-select">
        <div><inline-svg src="/gem.svg" width="18" height="18" class="mr-2" /></div>
        <div>Send Gems</div>
      </div>
    </ion-item>
    <ion-item class="item-container d-flex pointer" @click="onPress('block')">
      <div class="menu-item d-flex align-items-center no-select">
        <div><i class="ti-na mr-2" /></div>
        <div>Block User</div>
      </div>
    </ion-item>
    <ion-item class="item-container d-flex pointer" @click="onPress('report')">
      <div class="menu-item d-flex align-items-center no-select">
        <div><i class="ti-info-alt mr-2" /></div>
        <div>Report User</div>
      </div>
    </ion-item>
  </div>
</template>

<script lang="ts" setup>
import { OverlayInterface } from '@ionic/core';

const props = defineProps({
  instance: {
    type: Object as PropType<OverlayInterface>,
  },
});

const instance = toRef(props, 'instance') as any;

const onPress = (code?: string) => {
  if (instance) {
    try {
      instance.value.dismiss(code);
    } catch (e) {}
  }
  dismissByClick();
};

const dismissByClick = () => {
  try {
    const el = document.querySelector('body') as any;
    el.click();
  } catch (error) {}
};
</script>

<style scoped lang="sass">
.pointer
  cursor: pointer
.bottom-button
  background-color: var(--ion-color-primary) !important
  color: white
  padding: 10px 15px
  width: 100%
  text-align: center
  border-radius: 12px
  font-size: 0.8rem

.bottom-button:hover
  opacity: 0.8

.bottom-button-container
  padding: 0 0.25rem 0 0.25rem !important

.item-container:hover
  background-color: #eeeeee !important
  div
    background-color: #eeeeee !important

hr
  margin-bottom: 0
</style>

<template>
  <ion-modal
    class="modal-big"
    :is-open="isOpen"
    mode="md"
    :backdrop-dismiss="true"
    :show-backdrop="true"
    @didDismiss="dismissModal"
  >
    <div class="modal-inner-content p-3">
      <div class="d-flex align-items-center justify-content-center">
        <img class="cheer-img" src="/cheer.png" />
        <div class="cheer-title">You just made someone's day!</div>
      </div>
      <router-link
        :to="{ name: 'profile', params: { username: get(user, 'username') } }"
        class="d-flex align-items-center justify-content-center text-center"
      >
        Gems successfully sent to @{{ get(user, 'username') }}</router-link
      >
    </div>

    <ion-footer translucent>
      <ion-toolbar class="toolbar">
        <div class="d-flex justify-content-center text-center align-items-center">
          <ion-button color="transparent" class="close-btn" @click="dismissModal">
            Yay <i class="ti-heart mx-1"
          /></ion-button>
        </div>
      </ion-toolbar>
    </ion-footer>
  </ion-modal>
</template>

<script lang="ts" setup>
const props = defineProps({
  isOpen: {
    type: Boolean,
    default: false,
    required: true,
  },
  user: {
    type: Object,
    default: () => {},
  },
});
const emits = defineEmits(['close']);
const isOpen = toRef(props, 'isOpen');
const dismissModal = () => {
  emits('close');
};
</script>
<style lang="sass" scoped>
.cheer-title
  font: bolder 22px "Roboto Slab", sans-serif
  -webkit-text-fill-color: #A04BFE
.cheer-img
  width: 195px
  height: 195px
  object-fit: cover

.dark .toolbar
  --background: #17074c !important
.close-btn
  border-radius: 12px
  text-transform: none
  --background: #7050B7
  background:  #7050B7
  color: white
  height: 30px
  font-weight: 600
.modal-inner-content
  background: var(--ion-background-color, #fff)
  max-height: 70vh
  overflow: auto
  max-height: 70dvh

.modal-big
  --width: 600px
  --height: auto
  @media(max-width: 576px)
    --width: 100%
.close-button
  width: 50px

.title
  color: #88DC3B
  font-size: 20px
.dark .title
  color: #88DC3B !important
</style>

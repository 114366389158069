<template>
  <div class="wrapper">
    <div id="profile-comments" class="title mt-4 mb-3">
      Comments <span v-if="profileCommentsCount">({{ profileCommentsCount }})</span>
    </div>
    <PostComment :target="currentProfile" commenting-on-type="user" @posted="load" />

    <!-- Comments list -->
    <div class="w-100 mt-2">
      <div v-for="(comment, index) in allComments" :key="index">
        <comments-list-item
          :id="`comment-${comment.id}`"
          key="index"
          :comment="comment"
          :target="currentProfile"
          commenting-on-type="user"
          @posted="load"
          @commentDeleted="load"
        />
        <!-- Replies -->
        <div v-if="get(comment, 'replies.length')">
          <comments-list-item
            v-for="(reply, rIndex) in comment.replies.slice(0, 2)"
            :id="`comment-reply-${reply.id}`"
            :key="rIndex"
            :comment="reply"
            :target="currentProfile"
            commenting-on-type="user"
            @posted="load"
            @commentDeleted="load"
          />
        </div>

        <Transition name="slide-fade">
          <div v-if="comment.showAllReplies && comment.replies.length > 2">
            <comments-list-item
              v-for="reply in comment.replies.slice(2, comment.replies.length)"
              :id="`comment-reply-${reply.id}`"
              :key="reply.id"
              :comment="reply"
              :target="currentProfile"
              commenting-on-type="user"
              @posted="load"
              @commentDeleted="load"
            />
          </div>
        </Transition>

        <div v-if="comment.replies.length > 2" class="d-flex justify-content-center ml-auto mb-3 w-75">
          <ion-button
            class="tertiary"
            color="medium"
            size="small"
            @click.prevent="comment.showAllReplies = !comment.showAllReplies"
          >
            Show {{ comment.showAllReplies ? 'Less' : 'More' }}
          </ion-button>
        </div>
      </div>

      <div class="d-flex justify-content-center pb-4">
        <ChLoading size="sm" v-if="areCommentsLoading" class="spinner" />
        <a v-else-if="totalComments > allComments.length" href="#" @click.prevent="loadMoreComments"
          >Load {{ loadMoreandLess }}</a
        >
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { profileStore } from '@/shared/pinia-store/profile';
import CommentsListItem from '@/shared/components/CommentsListItem/index.vue';
import PostComment from '@/shared/components/PostComment.vue';
import { getCommentsCount, getComments } from '@/shared/actions/comments';
import constants from '@/shared/statics/constants';
import { authStore } from '@/shared/pinia-store/auth';
import store from '@/shared/store';
const route = useRoute();
const { isAuthenticated } = authStore();
const { currentProfile } = profileStore();

const allComments = ref([] as any[]);
const totalComments = ref(0);
const currentPage = ref(1);
const profileCommentsCount = ref(0);
const areCommentsLoading = ref(false);
const loadMoreandLess = ref('More');

const emits = defineEmits(['loading'])

const loggedInUser = computed(() => {
  const { user } = authStore();
  return user.value;
});

const getUserCharacters = (params: any) => {
  store.dispatch('CharactersModule/getUserCharacters', params);
};

const load = () => {
  fetchCommentsCount();
  fetchComments(1, (route.query?.cmnt || '') as string);
};

watch(currentProfile, () => {
  load();
});

const fetchCommentsCount = async () => {
  if (currentProfile.value?.id) {
    const resp = await getCommentsCount([currentProfile.value.id], constants.commentedAsTypes.USER);
    profileCommentsCount.value = get(resp, 'counts.0.comments_count', 0);
  }
};

const loadMoreComments = async () => {
  fetchComments(currentPage.value + 1);
};

const fetchComments = async (page = 1, cmnt?: string) => {
  areCommentsLoading.value = true;
  emits('loading', areCommentsLoading.value)
  if (!currentProfile.value?.id) {
    areCommentsLoading.value = true;
    return;
  }
  const params = {} as any;
  if (cmnt) params['h'] = cmnt;
  const resp = await getComments(currentProfile.value.id, constants.commentedAsTypes.USER, page, params);

  for (const comment of resp.results) {
    comment.showAllReplies = false;
  }

  if (page === 1) {
    allComments.value = resp.results;
  } else {
    allComments.value = allComments.value.concat(resp.results);
  }
  totalComments.value = resp.count;
  currentPage.value = page;
  areCommentsLoading.value = false;
  emits('loading', areCommentsLoading.value)
};

onMounted(() => {
  totalComments.value = 0;
  allComments.value = [];
  if (isAuthenticated.value) getUserCharacters({ id: loggedInUser.value.id });
  load();
});
</script>

<style lang="sass" scoped>
.comment-poster-img
  width: 60px
  height: 60px
  border-radius: 40px
  border: solid gray 0.1px
  object-fit: cover
.title
  font-size: 20px
  font-weight: bold
.sub-title
  font-size: 20px
  font-weight: bold
.small-title
  font-size: 13px
  font-weight: bold
.publish-btn
  width: 100px
  .spinner
    width: 15px
    height: 15px

.slide-fade-enter-active
  transition: all 0.5s ease-out

.slide-fade-leave-active
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1)

.slide-fade-enter-from,
.slide-fade-leave-to
  transform: translateY(-20px)
  opacity: 0
</style>
